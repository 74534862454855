import {
  MultipleChoiceQuestion,
  SingleChoiceQuestion,
  TextInputQuestion,
  TrueOrFalseQuestion,
} from "./QuestionTypes";

export const RenderQuestion = ({ typeId, answers, formikProps }) => {
  switch (typeId) {
    case 1:
      return (
        <SingleChoiceQuestion formikProps={formikProps} answers={answers} />
      );
    case 2:
      return (
        <MultipleChoiceQuestion formikProps={formikProps} answers={answers} />
      );
    case 3:
      return (
        <TrueOrFalseQuestion answers={answers} formikProps={formikProps} />
      );
    case 4:
    case 5:
      return <TextInputQuestion formikProps={formikProps} />;
    default:
      return null;
  }
};
