import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

export const AssessmentMetadata = () => {
  const { title, gradeName, subjectName } = useSelector(
    (state) => state.assessment
  );
  const { t } = useTranslation();
  return (
    <div className="w-[80%] mx-auto  p-4 mt-4 flex flex-col items-center gap-2 bg-white rounded-lg">
      <h1 className="text-lg lg:text-2xl font-bold ">
        {t("assessment.title")} : {title}
      </h1>
      <div className="flex justify-center items-center gap-2 text-sm lg:text-lg text-sky-600">
        <p>{subjectName}</p>
        <p>{gradeName}</p>
      </div>
    </div>
  );
};
