import parser from "html-react-parser";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

export const AssessmentPassage = () => {
  const { t } = useTranslation();
  const { head } = useSelector((state) => state.assessment);

  if (!head?.text) return null;

  return (
    <div className="container p-4 my-4 flex flex-col gap-4 bg-sky-100 rounded-lg">
      <p className="text-2xl font-bold ">{t("assessment.passage")}</p>
      <p className="text-sm font-medium text-sky-600 ">
        {t("assessment.passagePrompt")}
      </p>
      <div className="font-medium text-lg my-4 flex flex-col">
        {parser(head?.text)}
      </div>
    </div>
  );
};
