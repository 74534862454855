import React from "react";
import { useTranslation } from "react-i18next";
import { BsFileX } from "react-icons/bs";
import { useSelector } from "react-redux";

export const InCorrectCard = () => {
  const state = useSelector((state) => state.assessment);
  const wrongCount = state.results?.wrongCount;
  const questionCount = state.results?.questionCount;

  const { t } = useTranslation();

  return (
    <div className={`border p-5 rounded-md bg-red-100`}>
      <div className="flex">
        <div
          className={`p-2 w-12 h-12 rounded-full flex items-center justify-center bg-red-200 `}
        >
          <BsFileX size={22} />
        </div>
      </div>
      <div className="flex items-center gap-2 divide-x divide-red-400 py-4">
        <span className="flex items-center space-x-2">
          <span className="pe-2 font-sans text-lg text-slate-900">
            {t("results.wrong")}
          </span>
        </span>
        <span className="ps-2 text-red-800 font-medium">{wrongCount}</span>
      </div>
      <div className="mt-4 flex">
        <div
          className={` px-3 py-1 rounded-md
                      bg-red-50`}
        >
          <span className="text-lg font-bold">
            {wrongCount}/{questionCount}
          </span>
        </div>
      </div>
    </div>
  );
};
