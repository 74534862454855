import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  id: 0,
  userID: null,
  title: "",
  subjectName: "",
  gradeName: "",
  currentQuestion: 0,
  currentData: {
    id: 0,
    text: "",
    typeId: 0,
    rank: 0,
    answer: [],
    hint: "",
    imagePath: null,
    answers: null,
    duration: 5,
    head: null,
    columns: 0,
  },
  totalTime: 100,
  questions: [],
  savedAnswers: [],
  timers: [0],
  initialTimes: [0],
  results: {},
  skippedQuestions: [],
  price: 0,
};

const assessmentSlice = createSlice({
  name: "assessment",
  initialState,
  reducers: {
    saveID: (state, action) => {
      state.userID = action.payload;
    },
    loadData: (state, action) => {
      state.id = action.payload?.id;
      state.price = action.payload?.price;
      state.title = action.payload?.localizedName;
      state.gradeName = action.payload?.localizedGradeName;
      state.subjectName = action.payload?.localizedSubjectName;
      state.totalTime = action.payload?.maxDuration * 60;
      state.timers = action.payload?.questions.map((q) => q.duration * 60);
      state.initialTimes = action.payload?.questions.map(
        (q) => q.duration * 60
      );
      state.initialTimes.push(action.payload.maxDuration * 60);
    },
    loadQuestions: (state, action) => {
      state.questions = action.payload;
      state.currentData = action.payload[0];
    },
    nextQuestion: (state, action) => {
      if (!action.payload.answer) {
        if (!state.skippedQuestions.includes(state.currentQuestion + 1)) {
          state.skippedQuestions.push(state.currentQuestion + 1);
        }
      } else {
        state.skippedQuestions = state.skippedQuestions.filter(
          (i) => i !== state.currentQuestion + 1
        );
        state.questions[state.currentQuestion] = action.payload;
        state.savedAnswers[state.currentQuestion] = {
          questionId: action.payload.id,
          answer:
            action.payload.typeId !== 2
              ? action.payload.answer.toString()
              : null,
          multiAnswer:
            action.payload.typeId === 2
              ? action.payload.answer.map((i) => i.toString())
              : [],
        };
      }

      if (state.currentQuestion < state.questions.length - 1) {
        state.currentData = state.questions[state.currentQuestion + 1];
      }
      state.currentQuestion += 1;
    },
    prevQuestion: (state) => {
      state.currentData = state.questions[state.currentQuestion - 1];
      state.currentQuestion -= 1;
    },
    leapQuestion: (state, action) => {
      if (
        !state?.currentData?.answer &&
        state.currentQuestion < state.questions.length
      ) {
        if (!state.skippedQuestions.includes(state.currentQuestion + 1)) {
          state.skippedQuestions.push(state.currentQuestion + 1);
        }
      }
      state.currentQuestion = action.payload;
      state.currentData = state.questions[action.payload];
    },
    setTimer: (state, action) => {
      state.timers[state.currentQuestion] = action.payload;
    },
    tick: (state) => {
      if (state.timers[state.currentQuestion] > 0) {
        state.timers[state.currentQuestion] -= 1;
      }
    },
    saveResults: (state, action) => {
      state.results = action.payload;
    },
  },
});

export const {
  nextQuestion,
  leapQuestion,
  prevQuestion,
  setTimer,
  tick,
  loadData,
  loadQuestions,
  saveResults,
  saveID,
} = assessmentSlice.actions;

export default assessmentSlice.reducer;
