import React from "react";

export const TextInputQuestion = ({ formikProps }) => {

  return (
    <div
      className={`relative hover:shadow-md bg-slate-100 rounded-lg leading-none flex items-center m-2 w-full border-2 cursor-pointer border-blue-400`}
    >
      <input
        type="text"
        className="flex flex-1 w-full h-14 rounded-lg px-7 text-lg font-medium bg-slate-100 border-inherit"
        value={formikProps.values.answer ?? ''}
        onChange={(e) => formikProps.setFieldValue("answer", e.target.value)}
      />
    </div>
  );
};
