import axios from "axios";
import Cookies from "js-cookie";

const api = axios.create({
  baseURL: "https://api.365exams.com",
  headers: {
    Accept: "*/*",
    "Access-Control-Allow-Origin": "*",
  },
});

export const getAssessment = async (id) => {
  try {
    const { data } = await api.get(`/courses/Assessments/Get?id=${id}`);
    return data;
  } catch (e) {
    console.error(e);
  }
};

export const getAssessmentQuestions = async (id, page, size) => {
  try {
    const { data } = await api.get(
      `/courses/questions/list?PageNumber=${page}&PageSize=${size}&assessmentId=${id}`
    );
    return data;
  } catch (e) {
    console.error(e);
  }
};

export const getResults = async (values) => {
  try {
    const { data } = await api.post(
      `/courses/Assessments/Complete?id=${values.id}`,
      values.answers
    );
    return data;
  } catch (e) {
    console.error(e);
  }
};

export const submitReview = async (params) => {
  try {
    let result = await fetch(`https://api.365exams.com/Articles/Reviews/Add`, {
      method: "PUT",
      body: JSON.stringify(params),
      headers: {
        "Content-Type": "application/json",
      },
    });
    let data = await result.json();
    return data;
  } catch (err) {
    console.log(err);
  }
};

// Add request and response interceptors
api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.request.use(
  async (config) => {
    // Do something before request is sent
    const token = Cookies.get("Token");
    // console.log(token);
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  }
);
