import React from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import {
  AssessmentForm,
  AssessmentMetadata,
  AssessmentPassage,
  LoadingState,
} from "../components";
import { useFetchAssessment } from "../hooks";
import Error from "./Error";

const Assessment = () => {
  const [searchParams] = useSearchParams();
  const id = searchParams.get("assessmentId");
  const { isLoading, error } = useFetchAssessment(id);

  const examState = useSelector((state) => state.assessment);
  const { questions } = examState;

  if (error) {
    return <Error />;
  }

  if (isLoading || !questions?.length) {
    return <LoadingState />;
  }

  return (
    <main className="min-h-screen flex flex-col items-center justify-center">
      <AssessmentPassage />
      <AssessmentMetadata />
      <AssessmentForm />
    </main>
  );
};

export default Assessment;
