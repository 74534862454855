import React from "react";
import { submitReview } from "../services/api";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18n from "../locale/i18n";

export const SubmitRating = ({ stars }) => {
  const state = useSelector((state) => state.assessment);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState(false);
  const { t } = useTranslation();

  const submitRating = async (params) => {
    try {
      let res = await submitReview(params);
      navigate("/results");
    } catch (e) {
      console.error(e);
    }
    setIsLoading(false);
  };

  const handleSubmit = () => {
    setIsLoading(true);
    submitRating({
      reviewerId: state.userID,
      reviewedId: null,
      relatedObject: state.id.toString(),
      name: "Exam Rating",
      text: `rating by student: ${state.userID}`,
      date: new Date(),
      rate: stars,
    });
  };

  return (
    <div className="relative group">
      <button
        onClick={handleSubmit}
        className="relative px-10 py-4 shadow-md group-hover:shadow-lg duration-300 ease-in bg-sky-100 rounded-lg leading-none flex items-center justify-center w-full"
      >
        <span className="flex items-center ">
          <span className="pe-6 font-sans text-lg text-slate-900">
            {state.title}
          </span>
        </span>
        {isLoading ? (
          <div
            className="inline-block h-7 w-7 animate-spin rounded-full border-2 border-solid border-sky-600 border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
            role="status"
          >
            <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"></span>
          </div>
        ) : (
          <span className="ps-6 text-sky-900 text-lg group-hover:text-sky-600 transition duration-200">
            {t("rating.seeResults")}{" "}
            {i18n.resolvedLanguage === "ar" ? (
              <span>&larr;</span>
            ) : (
              <span>&rarr;</span>
            )}
          </span>
        )}
      </button>
    </div>
  );
};

export default SubmitRating;
