import React from "react";
import { Toaster } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { StarRating, SubmitRating } from "../components";

const Rating = () => {
  const [selected, setSelected] = React.useState(0);
  const { t } = useTranslation();

  return (
    <main className="min-h-screen px-8 py-16 ">
      <div className="grid gap-8 items-center justify-center">
        <div className="mt-40 mb-5">
          <h1 className="text-xl font-medium text-slate-500 text-center">
            {t("rating.pleaseAdd")}
          </h1>
          <StarRating
            totalStars={5}
            selectedStars={selected}
            onSelect={(val) => setSelected(val)}
          />
        </div>
        <SubmitRating stars={selected} />
      </div>
      <Toaster />
    </main>
  );
};

export default Rating;
