import React, { Suspense } from "react";
import { Provider } from "react-redux";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Footer, Header } from "./components";
import { Assessment, Error, Rating, Results, Review } from "./pages";
import { store } from "./store/store";
import i18n from "./locale/i18n";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Assessment />,
    errorElement: <Error />,
  },
  {
    path: "/rating",
    element: <Rating />,
    errorElement: <Error />,
  },
  {
    path: "/results",
    element: <Results />,
    errorElement: <Error />,
  },
  {
    path: "/review",
    element: <Review />,
    errorElement: <Error />,
  },
]);

const App = () => {
  document.body.dir = i18n.dir();
  return (
    <Provider store={store}>
      <Header />
      <Suspense fallback={() => <Error />}>
        <RouterProvider router={router} />
      </Suspense>
      <Footer />
    </Provider>
  );
};

export default App;
