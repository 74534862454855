import React from "react";
import toast from "react-hot-toast";
import { MdOutlineClose } from "react-icons/md";
import { IoIosWarning } from "react-icons/io";

export const notify = (heading, description) =>
  toast.custom(
    (t) => (
      <div
        className={`flex flex-row items-center gap-5 w-96 bg-orange-100 px-4 py-6 text-white shadow-2xl hover:shadow-none transform-gpu translate-y-0 hover:translate-y-1 rounded-xl relative transition-all duration-500 ease-in-out ${
          t.visible ? "top=0" : "-top-96"
        }`}
      >
        <div className="text-xl text-orange-400">
          <IoIosWarning />
        </div>
        <div className="flex flex-col items-start justify-center ml-4 cursor-default">
          <h1 className="text-base text-slate-900 font-semibold leading-none tracking-wider">
            {heading}
          </h1>
          <p className="text-sm text-slate-900 mt-2 leading-relaxed tracking-wider">
            {description}
          </p>
        </div>
        <div
          className={"absolute top-2 right-2 cursor-pointer text-lg"}
          onClick={() => toast.dismiss(t.id)}
        >
          <MdOutlineClose />
        </div>
      </div>
    ),
    { id: "unique-notification", position: "top-center" }
  );
