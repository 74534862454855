import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  leapQuestion,
  nextQuestion,
  prevQuestion,
} from "../store/assessmentSlice";

export const useAssessmentNavigation = () => {
  const dispatch = useDispatch();
  const { questions, currentQuestion, savedAnswers } = useSelector(
    (state) => state.assessment
  );

  const resetForm = (formikHelpers) => {
    const initialValues = {
      id: 0,
      text: "",
      typeId: -1,
      rank: 0,
      answer: "",
      hint: "",
      imagePath: null,
      answers: [],
      duration: 10,
      head: null,
      columns: 0,
    };
    formikHelpers.resetForm({ values: initialValues });
  };

  const handleNext = React.useCallback(
    (values, formikHelpers, setShowSubmit) => {
      setShowSubmit(false);
      dispatch(nextQuestion(values));

      resetForm(formikHelpers);

      if (questions[currentQuestion + 1]?.typeId === 2) {
        formikHelpers.setValues(questions[currentQuestion + 1].answers);
      }
    },
    [dispatch, questions, currentQuestion]
  );

  const handlePrevious = React.useCallback(
    (formikHelpers, setShowSubmit) => {
      setShowSubmit(false);
      if (currentQuestion > 0) {
        dispatch(prevQuestion());
        const prevAnswer = savedAnswers[currentQuestion - 1];
        formikHelpers.setValues(prevAnswer ? prevAnswer : {});
      }
    },
    [dispatch, savedAnswers, currentQuestion]
  );

  const handleQuestionNavigation = React.useCallback(
    (questionNumber, formikHelpers, setShowSubmit) => {
      setShowSubmit(false);
      dispatch(leapQuestion(questionNumber));

      const answer = savedAnswers[questionNumber];
      formikHelpers.setValues(answer ? answer : {});
    },
    [dispatch, savedAnswers]
  );

  return {
    handleNext,
    handlePrevious,
    handleQuestionNavigation,
  };
};
