import { useState, useEffect, useCallback } from "react";
import { getAssessment, getAssessmentQuestions } from "../services/api";
import { useDispatch } from "react-redux";
import { loadQuestions, loadData } from "../store/assessmentSlice";
import { notify } from "../utils/notification";

export const useFetchAssessment = (id) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    if (id) {
      try {
        const data = await getAssessment(id);
        console.log(data);
        const qData = await getAssessmentQuestions(id, 1, 20);
        dispatch(loadQuestions(qData.result));
        dispatch(loadData(data.result));
      } catch (e) {
        console.error(e);
        setError(true);
        notify("There was an issue fetching the assessment data.");
        // Further enhancement: Use toast or notification to show this error to the user.
      }
      setIsLoading(false);
    } else {
      setIsLoading(false);
      setError(true);
    }
  }, [id, dispatch]);

  // Exam data
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return { isLoading, error };
};
