import React from "react";
import { useTranslation } from "react-i18next";

export const SubmitButton = ({ onClick, isLoading }) => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col items-center justify-center my-10">
      <h1 className="text-lg font-medium text-slate-500 my-10">
        {t("assessment.finished")}
      </h1>
      <div className="relative group">
        <div className="absolute -inset-0.5 bg-gradient-to-r from-blue-400 to-indigo-300 rounded-lg blur opacity-75 group-hover:opacity-100 transition duration-800 group-hover:duration-200"></div>
        <button
          onClick={onClick}
          type="submit"
          disabled={isLoading}
          className="relative px-4 py-2 bg-sky-100 rounded-lg "
        >
          {isLoading ? (
            <div
              className="inline-block h-7 w-7 animate-spin rounded-full border-2 border-solid border-sky-600 border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
              role="status"
            >
              <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"></span>
            </div>
          ) : (
            <h1 className="font-serif text-lg text-sky-700">
              {t("assessment.submit")}
            </h1>
          )}
        </button>
      </div>
    </div>
  );
};
