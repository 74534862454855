import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import CircularTimer from "./CircularTimer";
import { InfoCard } from "./InfoCard";
import { useAssessmentSubmission } from "../hooks";

export const DetailCards = (props) => {
  const { t } = useTranslation();
  const { totalQuestions } = props;
  const state = useSelector((state) => state.assessment);
  const { handleSubmit, submitLoading } = useAssessmentSubmission();

  return (
    <div className="flex w-full flex-wrap lg:flex-nowrap lg:flex-col lg:w-1/5 gap-4 rounded-lg justify-between ">
      <CircularTimer
        questionIndex={state.currentQuestion}
        onTimeUp={() => {}}
        type={1}
        title={t("assessment.qTime")}
      />
      <CircularTimer
        questionIndex={state.initialTimes.length - 1}
        onTimerEnd={() => {
          if (state.initialTimes[state.initialTimes.length - 1] != 0)
            handleSubmit(true);
        }}
        type={1}
        title={t("assessment.totalTime")}
      />
      <InfoCard title={t("assessment.questions")} info={totalQuestions} />
    </div>
  );
};
