import React from "react";
import { useTranslation } from "react-i18next";
import { BsFileBarGraph } from "react-icons/bs";
import { useSelector } from "react-redux";

export const ResultCard = () => {
  const state = useSelector((state) => state.assessment);
  const correctCount = state.results?.correctCount;
  const wrongCount = state.results?.wrongCount;
  const questionCount = state.results?.questionCount;

  const { t } = useTranslation();

  return (
    <div className={`border p-5 rounded-md bg-sky-100`}>
      <div className="flex">
        <div
          className={`p-2 w-12 h-12 rounded-full flex items-center justify-center bg-sky-200 `}
        >
          <BsFileBarGraph size={22} />
        </div>
      </div>
      <div className="flex items-center gap-2 divide-x divide-sky-400 py-4">
        <span className="flex items-center space-x-2">
          <span className="pe-2 font-sans text-lg text-slate-900">
            {t("results.total")}
          </span>
        </span>
        <span className="ps-2 text-sky-800 font-medium">
          {correctCount >= wrongCount ? t("results.pass") : t("results.fail")}
        </span>
      </div>
      <span className="font-medium text-sky-800">{state.results.title}</span>
      <div className="mt-4 flex">
        <div
          className={` px-3 py-1 rounded-md
                      bg-sky-50`}
        >
          <span className="text-lg font-bold">
            {((correctCount / questionCount) * 100).toFixed(2)}%
          </span>
        </div>
      </div>
    </div>
  );
};
