import React from "react";
import { useTranslation } from "react-i18next";
import { BsFileCheck } from "react-icons/bs";
import { useSelector } from "react-redux";

export const CorrectCard = () => {
  const state = useSelector((state) => state.assessment);
  const correctCount = state.results?.correctCount;
  const questionCount = state.results?.questionCount;

  const { t } = useTranslation();

  return (
    <div className={`border p-5 rounded-md bg-green-100`}>
      <div className="flex">
        <div
          className={`p-2 w-12 h-12 rounded-full flex items-center justify-center bg-green-200 `}
        >
          <BsFileCheck size={22} />
        </div>
      </div>
      <div className="flex items-center gap-2 divide-x divide-green-400 py-4">
        <span className="flex items-center space-x-2">
          <span className="pe-2 font-sans text-lg text-slate-900">
            {t("results.correct")}
          </span>
        </span>
        <span className="ps-2 text-green-800 font-medium">{correctCount}</span>
      </div>
      <div className="mt-4 flex">
        <div
          className={`px-3 py-1 rounded-md
                      bg-green-50`}
        >
          <span className="text-lg font-bold">
            {correctCount}/{questionCount}
          </span>
        </div>
      </div>
    </div>
  );
};
