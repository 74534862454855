import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { AnswerReview } from "../components";
import { IoMdArrowBack } from "react-icons/io";
import { useNavigate } from "react-router";

const Review = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const pathName = window.location.pathname;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathName]);
  return (
    <div className="flex flex-col min-h-screen p-8">
      <p
        className="flex items-center font-semibold text-xl hover:text-lg cursor-pointer group"
        onClick={() => navigate(-1)}
      >
        <IoMdArrowBack className="text-xl group-hover:text-lg" />
        Back
      </p>
      <h1 className="text-xl font-bold lg:text-4xl md:text-2xl ">
        {t("review.title")}
      </h1>
      <AnswerReview />
    </div>
  );
};

export default Review;
