import React from "react";
import { MdArrowRight } from "react-icons/md";
import { BsFileText } from "react-icons/bs";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const ReviewCard = () => {
  const { t } = useTranslation();

  return (
    <div
      className={`border hover:shadow-lg cursor-pointer p-5 rounded-md bg-slate-100 justify-between flex flex-col`}
    >
      <Link to="/review" className="relative flex flex-col h-full w-full">
        <div className="flex relative">
          <div
            className={`p-2 w-12 h-12 rounded-full flex items-center justify-center bg-slate-200 `}
          >
            <BsFileText size={22} />
          </div>
        </div>
        <div className="flex items-center gap-2 divide-x divide-slate-400 py-4">
          <span className="flex items-center space-x-2">
            <span className="pe-2 font-sans text-lg text-slate-900">
              {t("results.review")}
            </span>
          </span>
          <div className="ps-2">
            <MdArrowRight />
          </div>
        </div>
      </Link>
    </div>
  );
};
