import React from "react";
import {
  CorrectCard,
  InCorrectCard,
  ResultCard,
  ReviewCard,
} from "../components";
import { useSelector } from "react-redux";
import { BsFileBarGraph } from "react-icons/bs";
import { MdArrowRight } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Results = () => {
  const { results } = useSelector((state) => state.assessment);
  const { t } = useTranslation();
  const isCorrected = results.isCorrected;
  const title = results.title;
  const description = results.description;
  return (
    <main className="min-h-screen px-8 py-16 ">
      {isCorrected ? (
        <div className="p-5">
          <h1 className="text-slate-900 text-3xl mb-5">Results</h1>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 md:gap-6">
            <ResultCard />
            <CorrectCard />
            <InCorrectCard />
            <ReviewCard />
          </div>
        </div>
      ) : (
        <div className="w-[80%] mx-auto">
          <h1 className="text-slate-900 text-3xl mb-5">Results</h1>
          <div className="border p-5 rounded-md bg-sky-100 hover:shadow-lg cursor-pointer">
            <Link to="https://365exams.com/StudentProfile">
              <div
                className={`p-2 w-12 h-12 rounded-full flex items-center justify-center bg-sky-200 `}
              >
                <BsFileBarGraph size={22} />
              </div>
              <p className="font-medium text-base lg:text-xl mt-3 text-sky-800">
                {title}
              </p>
              <p className="font-medium text-base lg:text-xl text-sky-800">
                {description}
              </p>
              <div className="flex items-center gap-2 divide-x divide-slate-400 py-4">
                <span className="flex items-center space-x-2">
                  <span className="pe-2 font-sans text-lg text-slate-900">
                    {t("results.profile")}
                  </span>
                </span>
                <div className="ps-2">
                  <MdArrowRight />
                </div>
              </div>
            </Link>
          </div>
        </div>
      )}
    </main>
  );
};

export default Results;
