import React from "react";
import { AnswerView } from "./Answers";
import { useSelector } from "react-redux";
import parser from "html-react-parser";
import { useTranslation } from "react-i18next";

export const AnswerReview = () => {
  const state = useSelector((state) => state.assessment);
  const questions = state.questions;
  const { t } = useTranslation();

  console.log("questions", questions);

  return (
    <div>
      {questions.map((item, idx) => {
        return (
          <div
            key={idx}
            className={`gap-5 m-4 items-center justify-center rounded-md p-4`}
          >
            <h1 className="text-xl font-medium text-slate-800 ">
              {parser(item.text)}
            </h1>
            {item.answer && item.typeId === 5 && (
              <div className="mt-5 gap-4 flex flex-col bg-sky-100 p-4 rounded-lg">
                <span className="text-lg font-medium text-slate-600">
                  {item.answer}
                </span>
                <span className="text-sm font-bold text-sky-400">
                  {t("review.mark")}
                </span>
              </div>
            )}
            {item.answers?.map((ans, i) => {
              return (
                <AnswerView
                  key={i}
                  text={ans.text}
                  trueAnswer={ans.isCorrect}
                  isCorrect={ans.id === item.answer && ans.isCorrect}
                  userAns={ans.id === item.answer}
                />
              );
            })}
            <hr className="my-12 h-px border-t-0 bg-transparent bg-gradient-to-r from-transparent via-neutral-500 to-transparent opacity-25 dark:opacity-100" />
          </div>
        );
      })}
    </div>
  );
};
