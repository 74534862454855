import React from "react";
import { Dialog, Disclosure, Popover, Transition } from "@headlessui/react";
import {
  FiMenu,
  FiChevronDown,
  FiInfo,
  FiUsers,
  FiBriefcase,
} from "react-icons/fi";
import { BiNews, BiCalendarEvent, BiXCircle } from "react-icons/bi";
import i18n from "../locale/i18n";
import { useTranslation } from "react-i18next";

const lngs = {
  en: { nativeName: "English" },
  ar: { nativeName: "عربي" },
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export const Header = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = React.useState(false);
  const { t } = useTranslation();

  const handleChangeLanguage = () => {
    if (i18n.resolvedLanguage === Object.keys(lngs)[0]) {
      i18n.changeLanguage(Object.keys(lngs)[1]);
    } else {
      i18n.changeLanguage(Object.keys(lngs)[0]);
    }
    document.body.dir = i18n.dir();
  };

  const company = [
    {
      name: t("header.about"),
      description: "",
      href: "https://365exams.com/About",
      icon: FiInfo,
    },
    {
      name: t("header.team"),
      description: "",
      href: "https://365exams.com/Team",
      icon: FiUsers,
    },
    {
      name: t("header.services"),
      description: "",
      href: "https://365exams.com/Services",
      icon: FiBriefcase,
    },
    {
      name: t("header.news"),
      description: "",
      href: "https://365exams.com/News",
      icon: BiNews,
    },
    {
      name: t("header.events"),
      description: "",
      href: "https://365exams.com/Events",
      icon: BiCalendarEvent,
    },
  ];

  return (
    <header className="sticky top-0 z-10 bg-slate-100 backdrop-filter backdrop-blur-md bg-opacity-30 border-b border-gray-200">
      <nav
        className="mx-auto flex max-w-7xl items-center justify-between p-4 lg:px-8"
        aria-label="Global"
      >
        <div className="flex lg:flex-1">
          <a
            href="https://365exams.com/"
            target="_blank"
            rel="noreferrer"
            className="-m-1.5 p-1.5"
          >
            <span className="sr-only">365 Exams</span>
            <img
              className="h-12 w-auto"
              src="/logo-dark.png"
              alt="Company Logo"
            />
          </a>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <FiMenu className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <Popover.Group className="hidden lg:flex lg:gap-x-12">
          <Popover className="relative">
            <Popover.Button className="flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900">
              {t("header.company")}
              <FiChevronDown
                className="h-5 w-5 flex-none text-gray-400"
                aria-hidden="true"
              />
            </Popover.Button>

            <Transition
              as={React.Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute -left-8 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5">
                <div className="p-4">
                  {company.map((item) => (
                    <div
                      key={item.name}
                      className="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50"
                    >
                      <div className="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                        <item.icon
                          className="h-6 w-6 text-gray-600 group-hover:text-green-600"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="flex-auto">
                        <a
                          href={item.href}
                          target="_blank"
                          rel="noreferrer"
                          className="block font-semibold text-gray-900"
                        >
                          {item.name}
                          <span className="absolute inset-0" />
                        </a>
                        <p className="mt-1 text-gray-600">{item.description}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </Popover.Panel>
            </Transition>
          </Popover>

          <a
            href="https://365exams.com/Programs"
            target="_blank"
            rel="noreferrer"
            className="text-sm font-semibold leading-6 text-gray-900"
          >
            {t("header.programs")}
          </a>
          <a
            href="https://365exams.com/Teachers"
            target="_blank"
            rel="noreferrer"
            className="text-sm font-semibold leading-6 text-gray-900"
          >
            {t("header.teachers")}
          </a>
          <a
            href="https://365exams.com/Contact"
            target="_blank"
            rel="noreferrer"
            className="text-sm font-semibold leading-6 text-gray-900"
          >
            {t("header.contact")}
          </a>
          {/* <a
            href="https://365exams.com/Account/Joinus"
            target="_blank"
            rel="noreferrer"
            className="text-sm font-semibold leading-6 text-gray-900"
          >
            {t("header.join")}
          </a> */}
          <button
            className={`leading-6 text-gray-900 underline underline-offset-8 hover:no-underline opacity-70 hover:opacity-100`}
            type="submit"
            onClick={handleChangeLanguage}
          >
            {t("header.lng")}
          </button>
        </Popover.Group>
        <div className="hidden lg:flex lg:flex-1 lg:justify-end gap-4 lg:gap-6">
          <a
            href="https://365exams.com/StudentProfile"
            target="_blank"
            rel="noreferrer"
            className="text-sm font-semibold leading-6 text-gray-900"
          >
            {t("header.Profile")}
          </a>
          {/* <a
            href="https://365exams.com/Account/Login"
            target="_blank"
            rel="noreferrer"
            className="text-sm font-semibold leading-6 text-gray-900"
          >
            {t("header.login")}{" "}
            <span aria-hidden="true">
              {i18n.resolvedLanguage === Object.keys(lngs)[0] ? (
                <span>&rarr;</span>
              ) : (
                <span>&larr;</span>
              )}
            </span>
          </a> */}
        </div>
      </nav>
      <Dialog
        as="div"
        className="lg:hidden"
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
      >
        <div className="fixed inset-0 z-10" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <a
              href="https://365exams.com"
              target="_blank"
              rel="noreferrer"
              className="-m-1.5 p-1.5"
            >
              <span className="sr-only">365 Exams</span>
              <img
                className="h-8 w-auto"
                src="/logo-dark.png"
                alt="Company Logo"
              />
            </a>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <BiXCircle className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                <Disclosure as="div" className="-mx-3">
                  {({ open }) => (
                    <>
                      <Disclosure.Button className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                        {t("header.company")}
                        <FiChevronDown
                          className={classNames(
                            open ? "rotate-180" : "",
                            "h-5 w-5 flex-none"
                          )}
                          aria-hidden="true"
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="mt-2 space-y-2">
                        {company.map((item) => (
                          <Disclosure.Button
                            key={item.name}
                            as="a"
                            href={item.href}
                            className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                          >
                            {item.name}
                          </Disclosure.Button>
                        ))}
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
                <a
                  href="https://365exams.com/Programs"
                  target="_blank"
                  rel={"noreferrer"}
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  {t("header.programs")}
                </a>
                <a
                  href="https://365exams.com/Teachers"
                  rel={"noreferrer"}
                  target="_blank"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  {t("header.teachers")}
                </a>
                <a
                  href="https://365exams.com/Contact"
                  target="_blank"
                  rel={"noreferrer"}
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  {t("header.contact")}
                </a>
                <button
                  className={`-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50 w-full text-start`}
                  type="submit"
                  onClick={handleChangeLanguage}
                >
                  {t("header.lng")}
                </button>
              </div>
              <div className="py-6">
                <a
                  href="https://365exams.com/Account/Login"
                  target="_blank"
                  rel={"noreferrer"}
                  className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  {t("header.login")}
                </a>
                <a
                  href="https://365exams.com/Account/Joinus"
                  target="_blank"
                  rel={"noreferrer"}
                  className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  {t("header.register")}
                </a>
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  );
};

export default Header;
