import { AnswerCard } from "../AnswerCard";

export const TrueOrFalseQuestion = ({ formikProps, answers }) => (
  <>
    {answers?.map((answer, index) => {
      return (
        <AnswerCard
          key={index}
          text={answer.text}
          selected={formikProps.values?.answer === answer.id}
          onSelect={() => formikProps.setFieldValue("answer", answer.id)}
        />
      );
    })}
  </>
);
