import React from "react";
import { useTranslation } from "react-i18next";
import { IoIosStar, IoIosStarOutline } from "react-icons/io";

export const StarRating = ({ totalStars = 5, onSelect, selectedStars = 0 }) => {
  const { t } = useTranslation();
  return (
    <div className="relative group">
      <div className="absolute animate-tilt  -inset-0.5 bg-gradient-to-r from-sky-300 to-indigo-400 rounded-lg blur opacity-75 group-hover:opacity-100 transition duration-1000 group-hover:duration-200"></div>
      <div className="relative items-center justify-between px-7 py-4 bg-slate-100 mt-10 rounded-lg leading-none flex flex-col ">
        <div className={`flex space-x-5  divide-slate-400 `}>
          <div className="flex space-x-1 pe-5 ">
            {Array.from({ length: totalStars }, (_, i) => (
              <Star
                key={i}
                selected={selectedStars > i}
                onSelect={() => onSelect(i + 1)}
              />
            ))}
          </div>
          <p className={`text-lg ml-2 font-sans ps-5 text-slate-800 `}>
            {selectedStars} {t("rating.of")} {totalStars} {t("rating.stars")}
          </p>
        </div>
      </div>
    </div>
  );
};

const Star = ({ selected = false, onSelect = () => {} }) => (
  <div
    onClick={onSelect}
    className={`text-2xl hover:scale-125 cursor-pointer transition-all duration-200 ${
      selected ? `text-yellow-400 transform scale-110` : `text-gray-300`
    }`}
  >
    {selected ? <IoIosStar /> : <IoIosStarOutline color={"#00000070"} />}
  </div>
);
