import { AnswerCard } from "../AnswerCard";

export const SingleChoiceQuestion = ({ formikProps, answers }) => (
  <>
    {answers?.map((answer, index) => (
      <AnswerCard
        key={index}
        text={answer.text}
        selected={formikProps.values?.answer === answer.id}
        onSelect={() => formikProps.setFieldValue("answer", answer.id)}
      />
    ))}
  </>
);
