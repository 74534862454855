import React from "react";
import { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const Error = () => {
  const navigate = useNavigate();
  // React.useEffect(() => {
  //   window.location.replace(`https://365exams.com`);
  // }, [navigate]);
  return (
    <div className="min-h-screen flex items-center justify-center">
      <Toaster />
      <div
        className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 w-96 rounded"
        role="alert"
      >
        <div className="flex items-center gap-4">
          <picture src={"/error.png"} alt="Error" className="w-16 h-16" />
          <div>
            <p className="font-bold">Error</p>
            <p>Something went wrong.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Error;
