import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getResults } from "../services/api";
import { saveResults } from "../store/assessmentSlice";
import { useNavigate } from "react-router-dom";

export const useAssessmentSubmission = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const examState = useSelector((state) => state.assessment);
  const { id: examId, savedAnswers, questions, price, userID } = examState;

  const [submitLoading, setSubmitLoading] = React.useState(false);
  const [isSkippedQuestions, setIsSkippedQuestions] = React.useState(false);

  const submitExam = async (params) => {
    try {
      let res = await getResults(params);
      console.log(res);
      if (res.success) {
        dispatch(saveResults(res.result));
        if (price > 0) {
          navigate("/rating");
        } else {
          navigate("/results");
        }
      }
    } catch (e) {
      console.error(e);
    }
    setSubmitLoading(false);
  };

  const handleSubmit = (force = false) => {
    if (
      savedAnswers.filter((item) => !!item).length === questions.length ||
      force
    ) {
      setSubmitLoading(true);
      submitExam({ id: examId, answers: savedAnswers, userID }).then((res) => {
        localStorage.clear();
      });
    } else {
      setIsSkippedQuestions(true);
    }
  };

  return {
    handleSubmit,
    submitLoading,
    isSkippedQuestions,
  };
};
