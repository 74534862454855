import React from "react";
import { SkeletonLoader } from "./Skeleton/SkeletonLoader";
import { SkeletonRectangle } from "./Skeleton/SkeletonRectangle";

export const LoadingState = () => {
  return (
    <main className="min-h-screen flex flex-col items-center">
      <div className="container h-screen p-4">
        <SkeletonLoader className="flex gap-2 my-10 ">
          <SkeletonRectangle
            lines={1}
            className="bg-gray-200 rounded-md h-20"
          />
        </SkeletonLoader>
        <SkeletonLoader className="flex gap-2 my-10 w-full flex-1 h-full">
          <SkeletonRectangle className="bg-gray-200 rounded-md w-full h-1/2 flex flex-col " />
        </SkeletonLoader>
      </div>
    </main>
  );
};
