import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";

const QUESTION_TYPE_THRESHOLD = 2;

export const CircularTimer = ({ questionIndex, title, type, onTimerEnd }) => {
  const initialTimeInSeconds =
    useSelector((state) => state.assessment.initialTimes[questionIndex]) || 0;

  const [seconds, setSeconds] = React.useState(() => {
    const savedSeconds = localStorage.getItem(`timer-${questionIndex}`);
    return savedSeconds !== null ? Number(savedSeconds) : initialTimeInSeconds;
  });
  const secondsRef = useRef(seconds);

  useEffect(() => {
    secondsRef.current = seconds;
  }, [seconds]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (secondsRef.current === 0) {
        onTimerEnd && onTimerEnd();
      } else {
        setSeconds((prevSeconds) => prevSeconds - 1);
        localStorage.setItem(
          `timer-${questionIndex}`,
          String(secondsRef.current - 1)
        );
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [questionIndex, initialTimeInSeconds, onTimerEnd]);

  useEffect(() => {
    const savedSeconds = localStorage.getItem(`timer-${questionIndex}`);
    const newSeconds =
      savedSeconds !== null ? Number(savedSeconds) : initialTimeInSeconds;
    setSeconds(newSeconds);
  }, [questionIndex, initialTimeInSeconds]);

  // Only for debug
  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.clear();
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const getColorBasedOnTime = (seconds, initialTimeInSeconds) => {
    const percentageTimeLeft = (seconds / initialTimeInSeconds) * 100;

    if (percentageTimeLeft > 50) {
      return {
        progressColor:
          type === QUESTION_TYPE_THRESHOLD ? "text-green-300" : "text-blue-300",
        pathColor:
          type === QUESTION_TYPE_THRESHOLD ? "text-green-100" : "text-blue-100",
      };
    } else if (percentageTimeLeft > 20) {
      return {
        progressColor: "text-yellow-300",
        pathColor: "text-yellow-100",
      };
    } else if (percentageTimeLeft > 10) {
      return {
        progressColor: "text-orange-300",
        pathColor: "text-orange-100",
      };
    } else {
      return {
        progressColor: "text-red-300",
        pathColor: "text-red-100",
      };
    }
  };

  const { progressColor, pathColor } = getColorBasedOnTime(
    seconds,
    initialTimeInSeconds
  );

  return (
    <div className="flex flex-col items-center justify-center lg:h-full w-[calc(50%_-_16px)] lg:w-full p-4 bg-white rounded-lg shadow-md gap-3 lg:min-h-[300px]">
      <svg
        className={`lg:w-24 lg:h-24 w-16 h-16 transform rotate-90 ${progressColor}`}
        viewBox="0 0 100 100"
      >
        <circle
          className={`stroke-current ${pathColor}`}
          fill="none"
          strokeWidth="10"
          strokeDasharray={2 * Math.PI * 45}
          strokeDashoffset="0"
          cx="50"
          cy="50"
          r="45"
        />
        <circle
          className="stroke-current"
          fill="none"
          strokeWidth="10"
          strokeDasharray={2 * Math.PI * 45}
          strokeDashoffset={
            (seconds / initialTimeInSeconds) * (2 * Math.PI * 45)
          }
          cx="50"
          cy="50"
          r="45"
        />
      </svg>
      <div className="text-gray-800 text-center ">
        <h1 className="texzt-xl lg:text-2xl font-bold mb-2">{`${Math.floor(
          seconds / 60
        )
          .toString()
          .padStart(2, "0")}:${(seconds % 60)
          .toString()
          .padStart(2, "0")}`}</h1>
        <h2 className="text-lg lg:text-xl">{title} </h2>
      </div>
    </div>
  );
};

export default CircularTimer;
