import React from "react";
import { Formik, ErrorMessage } from "formik";
import { RenderQuestion } from "./RenderQuestions";
import parser from "html-react-parser";
import { Pagination } from "./Pagination";
import { SubmitButton } from "./SubmitButton";
import { DetailCards } from "./Card";
import { useSelector } from "react-redux";
import { useAssessmentNavigation, useAssessmentSubmission } from "../hooks";
import { Toaster } from "react-hot-toast";
import { IoIosWarning } from "react-icons/io";

export const AssessmentForm = () => {
  const [showSubmit, setShowSubmit] = React.useState(false);

  const examState = useSelector((state) => state.assessment);
  const {
    currentData,
    questions,
    totalTime,
    currentQuestion,
    skippedQuestions,
  } = examState;

  const { handleNext, handlePrevious, handleQuestionNavigation } =
    useAssessmentNavigation();
  const { handleSubmit, submitLoading, isSkippedQuestions } =
    useAssessmentSubmission();

  React.useEffect(() => {
    if (currentQuestion === questions.length) {
      setShowSubmit(true);
    } else {
      setShowSubmit(false);
    }
  }, [currentQuestion, questions.length]);
  console.log(skippedQuestions);
  return (
    <Formik
      validateOnChange={false}
      enableReinitialize
      initialValues={{
        id: currentData?.id || 0,
        text: currentData?.text || "",
        typeId: currentData?.typeId || "",
        rank: currentData?.rank,
        answer: currentData?.answer,
        hint: currentData?.hint,
        imagePath: currentData?.imagePath,
        answers: currentData?.answers,
        duration: currentData?.duration,
        head: currentData?.head,
        columns: currentData?.columns,
      }}
      onSubmit={(values, formikHelpers) => handleNext(values, formikHelpers)}
    >
      {(formikProps) => (
        <div className="flex flex-col lg:flex-row w-full px-[10%] my-6 gap-5">
          <DetailCards
            totalTime={totalTime}
            totalQuestions={questions?.length}
          />
          <section className="flex-1 flex flex-col w-full gap-5 bg-white p-4 rounded-lg shadow-md">
            <Toaster />
            <Pagination
              formikProps={formikProps}
              handleNext={() =>
                handleNext(formikProps.values, formikProps, setShowSubmit)
              }
              handlePrev={() => handlePrevious(formikProps, setShowSubmit)}
              totalQuestions={questions?.length}
              handleQuestionNavigation={(val) =>
                handleQuestionNavigation(val, formikProps, setShowSubmit)
              }
            />
            <div className="w-full h-[1px] bg-gray-400" />
            <div className="flex flex-col justify-between h-full">
              <div>
                {showSubmit ? (
                  <>
                    <SubmitButton
                      isLoading={submitLoading}
                      onClick={() => {
                        handleSubmit(false);
                      }}
                    />
                    {isSkippedQuestions && !!skippedQuestions.length && (
                      <div
                        className={`flex flex-row items-center gap-2 m-auto w-auto sm:w-96 bg-red-400 px-4 py-6 text-white shadow-xl hover:shadow-none transform-gpu translate-y-0 hover:translate-y-1 rounded-xl relative transition-all duration-500 ease-in-out `}
                      >
                        <div className="text-6xl text-yellow-300">
                          <IoIosWarning />
                        </div>
                        <div className="flex flex-col items-start justify-center ml-4 cursor-default">
                          <h1 className="text-base text-slate-900 font-semibold leading-none tracking-wider">
                            Oops
                          </h1>
                          <p className="text-sm text-slate-900 mt-2 leading-relaxed tracking-wider break-all">
                            {`Seems like you missed questions: ${skippedQuestions?.join(
                              ","
                            )}`}
                          </p>
                        </div>
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    <div className="font-bold text-lg my-4 overflow-auto">
                      {parser(currentData?.text || "")}
                    </div>
                    <div className="mb-4">
                      <RenderQuestion
                        typeId={currentData?.typeId}
                        answers={currentData?.answers}
                        formikProps={formikProps}
                      />
                    </div>
                  </>
                )}
                <ErrorMessage name="answer" component="div" />
              </div>
            </div>
          </section>
        </div>
      )}
    </Formik>
  );
};
