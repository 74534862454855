import React from "react";

export const InfoCard = ({ title, info }) => {
  return (
    <div className="flex flex-col items-center justify-center w-full p-4 bg-black rounded-lg">
      <h1 className="text-4xl font-bold mb-2 text-slate-50">{info}</h1>
      <h2 className="text-xl text-slate-50 font-medium">{title}</h2>
    </div>
  );
};
