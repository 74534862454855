import React from "react";
import parser from "html-react-parser";

export const AnswerCard = ({ text, selected, onSelect }) => {
  return (
    <button
      onClick={onSelect}
      className={`relative hover:shadow-md px-7 py-4 bg-slate-100 rounded-lg leading-none flex items-center m-2 w-full border-2 cursor-pointer ${
        selected ? "border-blue-400" : "border-transparent"
      }`}
    >
      <span className="lg:text-lg sm:text-sm text-slate-900 font-medium ">
        {parser(text)}
      </span>
    </button>
  );
};
