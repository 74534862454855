import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

export const Pagination = ({
  totalQuestions,
  handleQuestionNavigation,
  handleNext,
  handlePrev,
  formikProps,
}) => {
  const { t } = useTranslation();
  const { currentQuestion, savedAnswers, questions } = useSelector(
    (state) => state.assessment
  );

  const getQuestionBG = (index) => {
    if (savedAnswers[index]?.answer || savedAnswers[index]?.multiAnswer) {
      return "bg-green-400 text-white";
    } else if (currentQuestion === index) {
      return "bg-slate-900 text-white";
    }
    return "hover:bg-gray-200";
  };

  // Question buttons
  const questionButtons = [...Array(totalQuestions)].map((_, index) => (
    <button
      key={index}
      type="submit"
      aria-label={`Navigate to question ${index + 1}`}
      className={`w-8 h-8 rounded-full ${getQuestionBG(index)}`}
      onClick={() => handleQuestionNavigation(index, formikProps)}
    >
      <span
        className={`${
          currentQuestion === index ? "text-white font-medium" : "opacity-100"
        }`}
      >
        {index + 1}
      </span>
    </button>
  ));

  return (
    <div className="flex justify-center gap-4 items-end flex-wrap px-4">
      {currentQuestion !== 0 && (
        <button
          type="submit"
          aria-label="Navigate to previous question"
          className="px-3 py-1 rounded hover:bg-gray-200 border-2 border-slate-200"
          onClick={() => handlePrev(formikProps)}
        >
          <span className="font-serif text-slate-800">
            {t("assessment.prev")}
          </span>
        </button>
      )}

      {questionButtons}

      {currentQuestion !== questions.length && (
        <button
          type="submit"
          aria-label={
            formikProps.values.answer ? "Next question" : "Skip question"
          }
          className={`py-1 px-3 rounded  ${
            formikProps.values.answer
              ? "bg-green-200 hover:bg-green-300"
              : "bg-slate-200 hover:bg-sky-100"
          }`}
          onClick={handleNext}
        >
          <span className={`font-serif text-black`}>
            {formikProps.values.answer
              ? t("assessment.next")
              : t("assessment.skip")}
          </span>
        </button>
      )}
    </div>
  );
};
