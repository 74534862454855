import { AnswerCard } from "../AnswerCard";

export const MultipleChoiceQuestion = ({ formikProps, answers }) => {
  const handleSelect = (answerID) => {
    let selectedAnswerIDs = formikProps.values?.answer || [];

    if (selectedAnswerIDs?.includes(answerID)) {
      // If the answer is currently selected, remove it from the array
      selectedAnswerIDs = selectedAnswerIDs.filter((id) => id !== answerID);
    } else {
      // If the answer is not currently selected, add it to the array
      selectedAnswerIDs?.push(answerID);
    }

    formikProps.setFieldValue("answer", selectedAnswerIDs);
  };

  return (
    <div>
      {answers?.map((answer, index) => (
        <AnswerCard
          key={index}
          text={answer.text}
          selected={formikProps.values?.answer?.includes(answer.id) || false}
          onSelect={() => handleSelect(answer.id)}
        />
      ))}
    </div>
  );
};
