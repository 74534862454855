import React from "react";
import parser from "html-react-parser";

export const AnswerView = ({ text, userAns, isCorrect, trueAnswer }) => {
  return (
    <div
      className={`relative  px-7 py-4 bg-white rounded-lg leading-none flex items-center m-2 w-full border-2 ${
        userAns
          ? isCorrect
            ? "border-green-400"
            : "border-red-400"
          : trueAnswer
          ? "border-green-400"
          : "border-transparent"
      }`}
    >
      <span className="text-lg text-slate-900">{parser(text)}</span>
    </div>
  );
};
